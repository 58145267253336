import Entreprise from "./entreprise";

export default class Travaux {
  constructor(data) {
    this.id = data.id;
    this.lib = data.lib ? data.lib : '';
    this.gallery = data.gallery
    this.entreprise = data.entreprise ? Entreprise.create(data.entreprise) : {lib: 'Toutes les entreprises'};
    this.is_default = data.is_default == 1 ? true : false;
    this.favoris = data.favoris == 1 ? true : false;
  }
  update(data) {
    this.lib = data.lib;
    this.gallery = data.gallery
    if(data.entreprise)
      this.entreprise = Entreprise.create(data.entreprise)
    else
      this.entreprise = {lib: "Toutes les entreprises"}
    this.is_default = data.is_default;
    this.favoris = data.favoris;
  }

  static create(data) {
    return new Travaux(data);
  }
}
