export default class SousTraitant {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.num_siret = data.num_siret;
    this.num_rge = data.num_rge;
  }
  update(data) {
    this.id = data.id;
    this.name = data.name;
    this.num_siret = data.num_siret;
    this.num_rge = data.num_rge;
  }

  static create(data) {
    return new SousTraitant(data);
  }
}
