import axios from "axios";
import domain from "@/environment";
const state = {
  Categories: [],
  CategorieLoading: false,
  CategorieRows: 0,
};
const getters = {
  getCategorieLoading: (state) => state.CategorieLoading,
  gettotalCategorieRows: (state) => state.CategorieRows,
  getAllCategorie: (state) => state.Categories,
};
const mutations = {
  SET_LOADINGCATEGORIE(state, payload) {
    if (payload) {
      state.CategorieLoading = payload;
    } else {
      state.CategorieLoading = false;
    }
  },
  ADD_CATEGORIES(state, payload) {
    state.Categories.push(payload);
    state.CategorieRows++;
  },
  UPDATE_CATEGORIES(state, payload) {
    for (let index = 0; index < state.Categories.length; index++) {
      if (state.Categories[index].id == payload.id) {
        state.Categories[index] = payload;
        break;
      }
    }
  },
  DELETE_CATEGORIES(state, payload) {
    for (let index = 0; index < state.Categories.length; index++) {
      if (state.Categories[index].id == payload.id) {
        state.Categories.splice(index, 1);
        state.CategorieRows--;
        break;
      }
    }
  },
  UPDATE_CATEGORIES_ROW(state, payload) {
    if (payload) {
      state.CategorieRows = payload;
    } else {
      state.CategorieRows = null;
    }
  },
  SET_CATEGORIES(state, payload) {
    if (payload) {
      state.Categories = payload;
    } else {
      state.Categories = [];
    }
  },
};
const actions = {
  async allCategorie({ commit }, payload) {
    commit("SET_LOADINGCATEGORIE", true);
    const params = {
      search: payload.search,
      page: payload.page,
      per_page: payload.per_page,
    };

    try {
      const responce = await axios.get(domain + "/categorieFichier", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_CATEGORIES", responce.data.data.data);
      commit("UPDATE_CATEGORIES_ROW", responce.data.data.total);
      commit("SET_LOADINGCATEGORIE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGCATEGORIE", false);
      throw error.response.data.message;
    }
  },
  async store_Categories({ commit }, payload) {
    commit("SET_LOADINGCATEGORIE", true);
    try {
      const responce = await axios.post(domain + `/categorieFichier`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("ADD_CATEGORIES", responce.data.data);
      commit("SET_LOADINGCATEGORIE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGCATEGORIE", false);
      throw error.response.data.message;
    }
  },
  async delete_Categories({ commit }, payload) {
    commit("SET_LOADINGCATEGORIE", true);
    try {
      await axios.delete(domain + `/categorieFichier/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_CATEGORIES", payload);
      commit("SET_LOADINGCATEGORIE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGCATEGORIE", false);
      throw error.response.data.message;
    }
  },
  async edit_Categories({ commit }, payload) {
    commit("SET_LOADINGCATEGORIE", true);
    try {
      const responce = await axios.put(
        domain + `/categorieFichier/update/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("UPDATE_CATEGORIES", responce.data.data);
      commit("SET_LOADINGCATEGORIE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGCATEGORIE", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
