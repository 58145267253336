export default class categorieArticle {
  constructor(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.categorie_metier = data.categorie_metier;
  }
  update(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.categorie_metier = data.categorie_metier;
  }

  static create(data) {
    return new categorieArticle(data);
  }
}
