import domain from "@/environment";
import axios from "axios";

const state = {
  systemeChauffages: [],
  systemeChauffage: null,
  systemeChauffageRows: 0,
  systemeChauffageLoading: false,
};

const getters = {
  getSystemeChauffage: (state) => state.systemeChauffage,
  getAllSystemeChauffages: (state) => state.systemeChauffages,
  getTotalRowSystemeChauffages: (state) => state.systemeChauffageRows,
  getLoadingSystemeChauffage: (state) => state.systemeChauffageLoading,
};

const mutations = {
  DO_NOTHING() {},
  SET_LOADING_SYSTEME_CHAUFFAGE(state, payload) {
    state.systemeChauffageLoading = payload || false;
  },
  SET_SYSTEME_CHAUFFAGE(state, payload) {
    state.systemeChauffage = payload || null;
  },
  PUSH_SYSTEME_CHAUFFAGE(state, payload) {
    state.systemeChauffages.push(payload);
  },
  UPDATE_SYSTEME_CHAUFFAGE(state, payload) {
    const index = state.systemeChauffages.findIndex(
      (sc) => sc.id === payload.id
    );
    if (index !== -1) {
      state.systemeChauffages[index] = {
        ...state.systemeChauffages[index],
        ...payload,
      };
    }
  },
  UPDATE_SYSTEME_CHAUFFAGE_ROW(state, payload) {
    state.systemeChauffageRows = payload || 0;
  },
  DELETE_SYSTEME_CHAUFFAGE(state, payload) {
    state.systemeChauffages = state.systemeChauffages.filter(
      (sc) => sc.id !== payload.id
    );
  },
  SET_SYSTEME_CHAUFFAGES(state, payload) {
    state.systemeChauffages = payload || [];
  },
};

const actions = {
  async allSystemeChauffages({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
    };
    try {
      const response = await axios.get(domain + `/systeme-chauffages`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_SYSTEME_CHAUFFAGES", response.data.data.data);
      commit("UPDATE_SYSTEME_CHAUFFAGE_ROW", response.data.data.total);
      return response.data.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async getSystemeChauffage({ commit }, payload) {
    try {
      const response = await axios.get(
        domain + `/systeme-chauffages/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_SYSTEME_CHAUFFAGE", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
async storeSystemeChauffage({ commit }, payload) {
  commit("SET_LOADING_SYSTEME_CHAUFFAGE", true);
  try {
    const response = await axios.post(domain + `/systeme-chauffages`, payload, {
      headers: {
        Authorization: `Bearer ` + this.state.token,
      },
    });
    commit("PUSH_SYSTEME_CHAUFFAGE", response.data.data);
    commit("SET_LOADING_SYSTEME_CHAUFFAGE", false);
    return response.data;
  } catch (error) {
    commit("SET_LOADING_SYSTEME_CHAUFFAGE", false);
    throw error; 
  }
},
  async editSystemeChauffage({ commit }, payload) {
    commit("SET_LOADING_SYSTEME_CHAUFFAGE", true);
    try {
      const response = await axios.put(
        domain + `/systeme-chauffages/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("UPDATE_SYSTEME_CHAUFFAGE", response.data.data);
      commit("SET_LOADING_SYSTEME_CHAUFFAGE", false);
      return response.data.data;
    } catch (error) {
      commit("SET_LOADING_SYSTEME_CHAUFFAGE", false);
      return error.response.data.message;
    }
  },
  async deleteSystemeChauffage({ commit }, payload) {
    commit("SET_LOADING_SYSTEME_CHAUFFAGE", true);
    try {
      await axios.delete(domain + `/systeme-chauffages/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_SYSTEME_CHAUFFAGE", payload);
      commit("SET_LOADING_SYSTEME_CHAUFFAGE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_SYSTEME_CHAUFFAGE", false);
      return error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
