import Entreprise from "./entreprise";
export default class Piece {
  constructor(data) {
    this.id = data.id;
    this.gallery = data.gallery
    this.categorie = data.categorie;
    if(data.entreprise.id==1){
      data.entreprise.lib='Toutes les entreprises'
    }
     this.entreprise = Entreprise.create(data.entreprise) ;
    this.favoris = data.favoris == 1 ? true : false;
    this.img = data.img;
    this.is_default = data.is_default == 1 ? true : false;
    this.lib = data.lib;
  }
  update(data) {
    this.id = data.id;
    this.categorie = data.categorie;
    this.favoris = data.favoris;
    this.gallery = data.gallery
    if(data.entreprise.id==1){
      data.entreprise.lib='Toutes les entreprises'
    }
    this.entreprise = Entreprise.create(data.entreprise);
    this.img = data.img;
    this.is_default = data.is_default;
    this.lib = data.lib;
  }

  static create(data) {
    return new Piece(data);
  }
}
