export default class Dictionnaire {
  constructor(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.lang = data.lang;
    this.key = data.key;
  }
  update(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.lang = data.lang;
    this.key = data.key;
  }
  format() {
    return {
      key: this.key,
      value: this.lib,
    };
  }

  static create(data) {
    return new Dictionnaire(data);
  }
}
