import domain from "@/environment";
import User from "../../models/user";
import axios from "axios";
import Router from "../../router/index";
const state = {
  user: window.localStorage.getItem("user")
    ? User.create(JSON.parse(window.localStorage.getItem("user")))
    : null,
  userloading: false,
  userRows: null,
  users: null,
  actif: null,
  firstAdmin: null,
};
const getters = {
  getOnlineUser: (state) => state.user,
  getAllUsers: (state) => state.users,
  getOnlineUserLoading: (state) => state.userloading,
  getTotalRowuser: (state) => state.userRows,
  getFirstAdmin: (state) => state.firstAdmin,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGUSER(state, payload) {
    if (payload) {
      state.userloading = payload;
    } else {
      state.userloading = false;
    }
  },
  SET_USER(state, payload) {
    if (payload) {
      state.user = User.create(payload);
    } else {
      state.user = null;
    }
  },
  SET_ADMIN(state, payload) {
    if (payload) {
      state.firstAdmin = User.create(payload);
    } else {
      state.firstAdmin = null;
    }
  },
  SET_USERS(state, payload) {
    if (payload) {
      state.users = payload.map((item) => User.create(item));
    } else {
      state.users = null;
    }
  },
  PUSH_USER(state, payload) {
    if (payload) {
      state.users.push(payload);
    }
  },
  UPDATE_USERS(state, payload) {
    for (let index = 0; index < state.users.length; index++) {
      if (state.users[index].id == payload.id) {
        state.users[index].update(payload);
        break;
      }
    }
  },
  UPDATE_USER(state, payload) {
    state.user.update(payload);

    window.localStorage.setItem("user", JSON.stringify(state.user));
  },

  UPDATE_USER_ROW(state, payload) {
    if (payload) {
      state.userRows = payload;
    } else {
      state.userRows = 0;
    }
  },
  DELETE_USER(state, payload) {
    for (let index = 0; index < state.users.length; index++) {
      if (state.users[index].id == payload.id) {
        state.users.splice(index, 1);
        break;
      }
    }
  },
};

const actions = {
  async onlineUser({ commit }) {
    try {
      const responce = await axios.get(domain + `/user-details`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("UPDATE_USER", responce.data.data);
      return responce.data;
    } catch (error) {
      commit("SET_TOKEN", null);
      window.localStorage.clear();
      Router.replace("/login");
      throw error.response.data.message;
    }
  },

  async entrepriseAdmin({ commit }, payload) {
    commit("DO_NOTHING");
    const params = {
      page: 1,
      per_page: 1,
      entreprise_id: payload,
      role: "user.entreprise-admin",
    };

    const responce = await axios.get(domain + `/all-users`, {
      headers: {
        Authorization: `Bearer ` + this.state.token,
      },
      params,
    });
    return responce.data.data.total;
  },

  async first_admin({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    try {
      const responce = await axios.get(
        domain + `/entreprise/firstadmin/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_ADMIN", responce.data.data);
      commit("SET_LOADINGUSER", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },
  async verifyEmail({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    var params = {
      email_u: payload,
    };
    try {
      const response = await axios.post(
        domain + `/user-VerifyEmailExist`,
        payload,
        {
          params,
        }
      );

      commit("SET_LOADINGUSER", false);
      return response.data.data.exist;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },
  async updateUser({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    try {
      const responce = await axios.post(domain + `/user-update`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("UPDATE_USER", responce.data.data);
      commit("SET_LOADINGUSER", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },
  async updateUsers({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    const params = {
      user_id: payload.id,
      nom: payload.nom,
      prenom: payload.prenom,
      tel_u: payload.tel_u,
      phone_number: payload.phone.phone_number,
      phone_iso_code: payload.phone.phone_iso_code,
      entreprise_id: payload.entreprise_id,
      role: payload.role,
      active: payload.active,
    };
    try {
      const responce = await axios.post(domain + `/user-update`, params, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("UPDATE_USERS", responce.data.data);

      commit("SET_LOADINGUSER", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },

  async all_users({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      entreprise_id: payload.entreprise_id,
      role: payload.role,
      with_all_users: payload.with_all_users,
    };
    try {
      const responce = await axios.get(domain + `/all-users`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_USERS", responce.data.data.data);
      commit("UPDATE_USER_ROW", responce.data.data.total);
      commit("SET_LOADINGUSER", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },

  async delete_user({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    try {
      await axios.delete(domain + `/users/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_USER", payload);
      commit("UPDATE_USER_ROW", state.userRows - 1);
      commit("SET_LOADINGUSER", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },
  async register({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    const params = {
      nom: payload.nom,
      prenom: payload.prenom,
      email_u: payload.email_u,
      phone_number: payload.phone_number,
      phone_iso_code: payload.phone_iso_code,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
    };
    try {
      await axios.post(domain + `/user-register`, null, {
        params,
      });
      commit("SET_LOADINGUSER", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },
  async entreprise_register({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    const params = {
      lib: payload.lib,
      email: payload.email,
      pays: payload.pays,
      ville: payload.ville,
      phone_number: payload.entreprise_phone_number,
      phone_iso_code: payload.entreprise_phone_iso_code,
      rue: payload.rue,
      cp: payload.cp,
      rcs: payload.rcs,
    };
    try {
      await axios.post(domain + `/user-register-entreprise`, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_LOADINGUSER", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },

  async store_user({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    const params = {
      nom: payload.nom,
      prenom: payload.prenom,
      email_u: payload.email_u,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
      tel_u: payload.tel_u.phone_number,
      phone_number: payload.tel_u.phone_number,
      phone_iso_code: payload.tel_u.phone_iso_code,
      entreprise_id: payload.entreprise,
      role: payload.role,
      active: 1,
    };

    try {
      await axios.post(domain + `/users`, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("UPDATE_USER_ROW", state.userRows + 1);
      commit("SET_LOADINGUSER", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },
  async forgot_password({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    const params = {
      email_u: payload,
    };
    try {
      await axios.post(domain + `/forgot-password`, payload, {
        params,
      });
      commit("SET_LOADINGUSER", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },

  async reset_password({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    try {
      await axios.post(domain + `/reset-password`, payload);
      commit("SET_LOADINGUSER", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },

  async visibilite({ commit }, payload) {
    commit("SET_LOADINGUSER", true);
    try {
      await axios.post(domain + `/visibiliteCommercial`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        payload,
      });
      commit("SET_LOADINGUSER", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGUSER", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
