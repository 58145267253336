import domain from "@/environment";
import categorieArticle from "../../models/categorieArticle";
import visibleArticle from "../../models/visibleArticle";
import visibleMetier from "../../models/visibleMetier";
import axios from "axios";

const state = {
  prestation: null,
  prestations: [],
  prestationsV: [],
  metierV: [],
  prestationLoading: false,
  prestationVRows: 0,
  prestationRows: 0,
};
const getters = {
  getprestation: (state) => state.prestation,
  getAllprestation: (state) => state.prestations,
  getAllprestationV: (state) => state.prestationsV,
  getAllmetierV: (state) => state.metierV,
  getLoadingprestation: (state) => state.prestationLoading,
  getTotalRowPrestation: (state) => state.prestationRows,
  getTotalRowPrestationV: (state) => state.prestationVRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGPRESTATION(state, payload) {
    if (payload) {
      state.prestationLoading = payload;
    } else {
      state.prestationLoading = false;
    }
  },
  SET_PRESTATION(state, payload) {
    if (payload) {
      state.prestation = categorieArticle.create(payload);
    } else {
      state.prestation = null;
    }
  },
  PUSH_PRESTATION(state, payload) {
    state.prestations.push(categorieArticle.create(payload));
  },
  UPDATE_PRESTATION(state, payload) {
    for (let index = 0; index < state.prestations.length; index++) {
      if (state.prestations[index].id == payload.id) {
        state.prestations[index].update(payload);
        break;
      }
    }
  },

  DELETE_PRESTATION(state, payload) {
    for (let index = 0; index < state.prestations.length; index++) {
      if (state.prestations[index].id == payload.id) {
        state.prestations.splice(index, 1);
        break;
      }
    }
  },
  SET_PRESTATIONS(state, payload) {
    if (payload) {
      state.prestations = payload.map((item) => categorieArticle.create(item));
    } else {
      state.prestations = [];
    }
  },
  SET_METIER(state, payload) {
    if (payload) {
      state.metierV = payload.map((item) => visibleMetier.create(item));
    } else {
      state.metierV = [];
    }
  },
  SET_PRESTATIONSV(state, payload) {
    if (payload) {
      state.prestationsV = payload.map((item) => visibleArticle.create(item));
    } else {
      state.prestationsV = [];
    }
  },
  UPDATE_PRESTATION_ROW(state, payload) {
    if (payload) {
      state.prestationRows = payload;
    } else {
      state.prestationRows = 0;
    }
  },
  UPDATE_PRESTATIONV_ROW(state, payload) {
    if (payload) {
      state.prestationVRows = payload;
    } else {
      state.prestationVRows = 0;
    }
  },
};

const actions = {
  async allprestation({ commit }, payload) {
    commit("SET_LOADINGPRESTATION", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      entreprise: payload.entreprise,
      default: payload.default,
      with_default: payload.with_default,
    };
    try {
      const responce = await axios.get(domain + "/categorie-article", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_PRESTATIONS", responce.data.data.data);
      commit("UPDATE_PRESTATION_ROW", responce.data.data.total);
      commit("SET_LOADINGPRESTATION", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGPRESTATION", false);
      throw error.response.data.message;
    }
  },
  async visibilite_prestation({ commit }) {
    commit("SET_LOADINGPRESTATION", true);
    try {
      const response = await axios.get(
        domain + "/visibilite-categorie-article",
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_PRESTATIONSV", response.data.data);
      commit("UPDATE_PRESTATIONV_ROW", response.data.length);
      commit("SET_LOADINGPRESTATION", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGPRESTATION", false);
      throw error.response.data.message;
    }
  },
  async visibilite_metier({ commit }) {
    commit("SET_LOADINGPRESTATION", true);
    try {
      const response = await axios.get(
        domain + "/visibilite-categorie-metier",
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_LOADINGPRESTATION", false);
      commit("SET_METIER", response.data.data);
      // commit("UPDATE_PRESTATIONV_ROW", response.data.length);
      return true;
    } catch (error) {
      commit("SET_LOADINGPRESTATION", false);
      throw error.response.data.message;
    }
  },

  async prestation({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/TypePrestations/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_PRESTATION", responce.data.data);
      return responce.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async store_prestation({ commit }, payload) {
    commit("SET_LOADINGPRESTATION", true);
    const params = {
      lib: payload.lib,
      entreprise_id: payload.entreprise_id,
      lang: "fr",
    };
    try {
      const responce = await axios.post(domain + `/TypePrestations`, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("PUSH_PRESTATION", responce.data.data);
      commit("UPDATE_PRESTATION_ROW", state.prestationRows + 1);
      commit("SET_LOADINGPRESTATION", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGPRESTATION", false);
      throw error.response.data.message;
    }
  },
  async store_visibilite_categorie_article({ commit }, payload) {
    commit("SET_LOADINGPRESTATION", true);
    var params = {
      categorie_article_id: payload.categorie_article_id,
    };
    try {
      await axios.post(domain + `/visibilite-categorie-article`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_LOADINGPRESTATION", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGPRESTATION", false);
      throw error.response.data.message;
    }
  },
  async store_visibilite_categorie_metier({ commit }, payload) {
    commit("SET_LOADINGPRESTATION", true);
    var params = {
      categorie_metier_id: payload.categorie_metier_id,
    };
    try {
      await axios.post(domain + `/visibilite-categorie-metier`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_LOADINGPRESTATION", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGPRESTATION", false);
      throw error.response.data.message;
    }
  },
  async edit_prestation({ commit }, payload) {
    commit("SET_LOADINGPRESTATION", true);
    try {
      const responce = await axios.put(
        domain + `/TypePrestations/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_PRESTATION", responce.data.data);
      commit("SET_LOADINGPRESTATION", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGPRESTATION", false);
      throw error.response.data.message;
    }
  },
  async delete_prestation({ commit }, payload) {
    commit("SET_LOADINGPRESTATION", true);
    try {
      await axios.delete(domain + `/TypePrestations/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_PRESTATION", payload);
      commit("UPDATE_PRESTATION_ROW", state.prestationRows - 1);
      commit("SET_LOADINGPRESTATION", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGPRESTATION", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
