import domain from "@/environment";
import Agenda from "../../models/agenda";
import axios from "axios";

const state = {
  agenda: null,
  activity: null,
  agendaLoading: false,
  agendaRows: 0,
};
const getters = {
  getAgenda: (state) => state.agenda,
  getActivity: (state) => state.activity,
  getagendaLoading: (state) => state.agendaLoading,
  getAgendaRows: (state) => state.agendaRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_agenda(state, payload) {
    if (payload) {
      state.agenda = payload.map((item) => Agenda.create(item));
    } else {
      state.agenda = null;
    }
  },

  SET_activity(state, payload) {
    if (payload) {
      state.activity = Agenda.create(payload);
    } else {
      state.activity = null;
    }
  },

  SET_AGENDALOADING(state, payload) {
    if (payload) {
      state.agendaLoading = payload;
    } else {
      state.agendaLoading = false;
    }
  },

  SET_EVENT(state, payload) {
    if (payload) state.agenda.push(Agenda.create(payload));
  },

  DELETE_EVENT(state, payload) {
    for (let index = 0; index < state.agenda.length; index++) {
      if (state.agenda[index].id == payload.id) {
        state.agenda.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_EVENT_ROW(state, payload) {
    if (payload) {
      state.agendaRows = payload;
    } else {
      state.agendaRows = 0;
    }
  },

  UPDATE_EVENT(state, payload) {
    for (let index = 0; index < state.agenda.length; index++) {
      if (state.agenda[index].id == payload.id) {
        state.agenda[index].update(payload);
        break;
      }
    }
  },
};

const actions = {
  async store_event({ commit }, payload) {
    commit("SET_AGENDALOADING", true);
    var params = {
      titre: payload.titre,
      description: payload.description,
      date_debut: payload.date_debut,
      date_fin: payload.date_fin,
      entreprise_id: payload.entreprise_id,
      user_id: payload.user_id,
      done: payload.done,
      type: payload.type,
      cp: payload.cp,
      ville: payload.ville,
      adresse: payload.adresse,
    };
    if (payload.client_id) params.client_id = payload.client_id;

    try {
      const responce = await axios.post(domain + `/activity`, params, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_EVENT", responce.data.data);
      commit("UPDATE_EVENT_ROW", state.agendaRows + 1);
      commit("SET_AGENDALOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_AGENDALOADING", false);
      throw error.response.data.message;
    }
  },

  async all_rendezvous({ commit }, payload) {
    commit("SET_AGENDALOADING", true);
    const params = {
      date_debut: payload.date_debut,
      date_fin: payload.date_fin,
      entreprise_id: payload.entreprise_id,
      user_id: payload.user_id,
      client_id: payload.client_id,
      type: payload.type,
      done: payload.done,
      statut: payload.filtrestatut,
    };
    try {
      const responce = await axios.get(domain + `/activity`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_agenda", responce.data.data);
      commit("UPDATE_EVENT_ROW", responce.data.data.length);
      commit("SET_AGENDALOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_AGENDALOADING", false);
      throw error.response.data.message;
    }
  },

  async activity({ commit }, payload) {
    commit("SET_AGENDALOADING", true);
    try {
      const responce = await axios.get(domain + `/activity/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("SET_activity", responce.data.data);
      commit("SET_AGENDALOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_AGENDALOADING", false);
      throw error.response.data.message;
    }
  },

  async edit_event({ commit }, payload) {
    commit("SET_AGENDALOADING", true);
    try {
      const responce = await axios.put(
        domain + `/activity/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_EVENT", responce.data.data);
      commit("SET_AGENDALOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_AGENDALOADING", false);
      throw error.response.data.message;
    }
  },

  async delete_event({ commit }, payload) {
    commit("SET_AGENDALOADING", true);
    try {
      await axios.delete(domain + `/activity/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_EVENT", payload);
      commit("UPDATE_EVENT_ROW", state.agendaRows - 1);
      commit("SET_AGENDALOADING", false);
      return true;
    } catch (error) {
      commit("SET_AGENDALOADING", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
