export default class Gallery {
  constructor(data) {
    this.id = data.id;
    this.titre = data.titre;
    this.image = data.image;
  }
  update(data) {
    this.id = data.id;
    this.titre = data.titre;
    this.image = data.image;
  }

  static create(data) {
    return new Gallery(data);
  }
}
