import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.component("font-awesome-icon", FontAwesomeIcon);

import {
  faUserSecret,
  faPlus,
  faEdit,
  faTrash,
  faPencilAlt,
  faHeart,
  faPlusCircle,
  faSearch,
  faFilter,
  faCog,
  faBars,
  faUser,
  faBuilding,
  faCube,
  faHome,
  faWrench,
  faUsers,
  faAngleLeft,
  faAngleRight,
  faSuitcase,
  faTimes,
  faArrowUp,
  faArrowDown,
  faBriefcase,
  faAlignLeft,
  faStar,
  faSign,
  faCalendar,
  faMinusCircle,
  faHeartBroken,
  faBan,
  faCheck,
  faTimesCircle,
  faList,
  faDownload,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faGlobe,
  faListOl,
  faChevronLeft,
  faUserAlt,
  faFileInvoiceDollar,
  faReceipt,
  faSignal,
  faArrowLeft,
  faCopy,
  faStore,
  faCheckCircle,
  faCamera,
  faArrowRight,
  faPhone,
  faBook,
  faImage,
  faCreditCard,
  faBell,
  faBellSlash,
  faBookmark
} from "@fortawesome/free-solid-svg-icons";
import { faCcStripe, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faCaretSquareLeft, faFile, faFileAlt, faUserCircle,faEnvelope,
  faMap
} from "@fortawesome/free-regular-svg-icons";

library.add(faUserSecret,
  faPlus,
  faEdit,
  faTrash,
  faPencilAlt,
  faHeart,
  faPlusCircle,
  faSearch,
  faFilter,
  faUser,
  faBuilding,
  faCube,
  faHome,
  faWrench,
  faUsers,
  faAngleLeft,
  faAngleRight,
  faSuitcase,
  faBriefcase,
  faAlignLeft,
  faSign,
  faCalendar,
  faHeartBroken,
  faBan,
  faCheck,
  faTimesCircle,
  faList,
  faDownload,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faGlobe,
  faListOl,
  faFile,
  faFileAlt,
  faUserAlt,
  faUserCircle,
  faFileInvoiceDollar,
  faReceipt,
  faSignal,
  faArrowLeft,
  faCopy,
  faStore,
  faCheckCircle,
  faCamera,
  faCcStripe,
  faArrowRight,
  faArrowLeft,
  faPhone,
  faEnvelope,
  faMap,
  faChevronUp,
  faCreditCard,
  faBell,
  faBellSlash,
  faBookmark
);
library.add(faUserSecret, faPlus, faEdit, faTrash, faPencilAlt, faHeart, faPlusCircle, faSearch, faFilter, faMinusCircle);
library.add(faInstagram);
library.add(faCaretSquareLeft);
library.add(faCog);
library.add(faBars);
library.add(faTimes);
library.add(faArrowUp, faArrowDown);
library.add(faStar, faBook, faImage);