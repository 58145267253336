import domain from "@/environment";
import Notification from "../../models/notification";
import axios from "axios";

const state = {
  notifications: [],
  notificationLoading: false,
  notificationRows: 0,
};
const getters = {
  getNotifs: (state) => state.notifications,
  getNotifLoading: (state) => state.notificationLoading,
  getNotifRows: (state) => state.notificationRows,
};
const mutations = {
  DO_NOTHING() { },
  SET_NOTIFS(state, payload) {
    if (payload) {
      state.notifications = payload.map((item) => Notification.create(item));
    } else {
      state.notifications = [];
    }
  },
  SET_NOTIF(state, payload) {
    if (payload) {
      state.notifications.unshift(Notification.create(payload))
    }
  },

  SET_NOTIFLOADING(state, payload) {
    if (payload) {
      state.notificationLoading = payload;
    } else {
      state.notificationLoading = false;
    }
  },

  UPDATE_NOTIF_ROW(state, payload) {
    if (payload) {
      state.notificationRows = payload;
    } else {
      state.notificationRows = 0;
    }
  },

  SET_NOTIF_ROW(state) {
    state.notificationRows = 0
    for (let index = 0; index < state.notifications.length; index++) {
      if (!state.notifications[index].seen) {
        state.notificationRows++
      }
    }
  },

  UPDATE_NOTIF(state, payload) {
    for (let index = 0; index < state.notifications.length; index++) {
      if (state.notifications[index].id == payload.id) {
        if (state.notifications[index].seen == false) {
          state.notificationRows--
          state.notifications[index].seen = true
        }
        break;
      }
    }
  },

  DELETE_NOTIFS(state) {
    state.notifications = []
    state.notificationRows = 0
  },
};

const actions = {
  async set_notif({ commit }, payload) {
    commit("SET_NOTIFLOADING", true);
    try {
      commit("SET_NOTIF", payload);
      commit("SET_NOTIFLOADING", false);
      return true;
    } catch (error) {
      commit("SET_NOTIFLOADING", false);
      throw error.response.data.message
    }
  },
  async all_notifs({ commit }, payload) {
    commit("SET_NOTIFLOADING", true);
    const params = {
      seen: payload ? payload.seen : null,
    };
    try {
      const responce = await axios.get(
        domain +
        `/notifications`,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
          params,
        }
      );
      commit("SET_NOTIFS", responce.data.data.data);
      commit("SET_NOTIF_ROW");
      commit("SET_NOTIFLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_NOTIFLOADING", false);
      throw error.response.data.message
    }
  },

  async edit_notifs({ commit }, payload) {
    commit("SET_NOTIFLOADING", true);
    const params = {
      seen: true
    }
    try {
      await axios.put(
        domain + `/notifications/` + payload.id,
        null,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
          params
        }
      );
      commit("UPDATE_NOTIF", payload);
      // commit("UPDATE_NOTIF_ROW", state.notificationRows -1 );
      commit("SET_NOTIFLOADING", false);
      return true;
    } catch (error) {
      commit("SET_NOTIFLOADING", false);
      throw error.response.data.message
    }
  },

  async delete_notifs({ commit }) {
    commit("SET_NOTIFLOADING", true);
    try {
      const response = await axios.delete(domain + `/notification-delete`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_NOTIFS");
      commit("SET_NOTIFLOADING", false);

      return response;
    } catch (error) {
      commit("SET_NOTIFLOADING", false);
      return error;
    }
  },
};

export default { state, mutations, getters, actions };
