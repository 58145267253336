export default class visibleArticle {
  constructor(data) {
    this.id = data.categorie_metier_id;
  }
  update(data) {
    this.id = data.categorie_metier_id;
  }
  static create(data) {
    return new visibleArticle(data);
  }
}
