import domain from "@/environment";
import Product from "../../models/product";
import axios from "axios";
// import index from "../index";

const state = {
  product: null,
  products: [],
  productLoading: false,
  productRows: 0,
};
const getters = {
  getProduct: (state) => state.product,
  getAllProducts: (state) => state.products,
  getProductLoading: (state) => state.productLoading,
  getTotalRowProduct: (state) => state.productRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_PRODUCT(state, payload) {
    if (payload) {
      state.product = Product.create(payload);
    } else {
      state.product = null;
    }
  },

  SET_PRODUCTS(state, payload) {
    if (payload) {
      state.products = payload.map((item) => Product.create(item));
    } else {
      state.products = [];
    }
  },
  SET_PRODUCTLOADING(state, payload) {
    if (payload) {
      state.productLoading = payload;
    } else {
      state.productLoading = false;
    }
  },
  PUSH_PRODUCT(state, payload) {
    state.products.push(Product.create(payload));
  },
  UPDATE_PRODUCT(state, payload) {
    for (let index = 0; index < state.products.length; index++) {
      if (state.products[index].id == payload.id) {
        state.products[index].update(payload);
        break;
      }
    }
  },
  DELETE_PRODUCT(state, payload) {
    for (let index = 0; index < state.products.length; index++) {
      if (state.products[index].id == payload.id) {
        state.products.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_PRODUCT_ROW(state, payload) {
    if (payload) {
      state.productRows = payload;
    } else {
      state.productRows = 0;
    }
  },
};

const actions = {
  async all_products({ commit }, payload) {
    commit("SET_PRODUCTS");
    commit("SET_PRODUCTLOADING", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      entreprise_id: payload.entreprise,
      default: payload.default,
      categorie_article_id: payload.categorie_article_id,
    };
    try {
      const responce = await axios.get(domain + `/produits`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_PRODUCTS", responce.data.data.data);
      commit("UPDATE_PRODUCT_ROW", responce.data.data.total);
      commit("SET_PRODUCTLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_PRODUCTLOADING", false);
      throw error.response.data.message;
    }
  },

  async product({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/produits/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_PRODUCT", responce.data.data);
      return responce.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async store_product({ commit }, payload) {
    commit("SET_PRODUCTLOADING", true);
  
    try {
     
      const responce = await axios.post(domain + `/produits`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
          "Content-Type": "multipart/form-data",
        },
      });
      commit("PUSH_PRODUCT", responce.data.data);
      commit("UPDATE_PRODUCT_ROW", state.productRows + 1);
      commit("SET_PRODUCTLOADING", false);
      return Product.create(responce.data.data);
    } catch (error) {
      commit("SET_PRODUCTLOADING", false);
      throw error.response.data.message;
    }
  },
  async store_productJSON({ commit }, payload) {
    commit("SET_PRODUCTLOADING", true);
  
    try {
     
      const responce = await axios.post(domain + `/produits`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("PUSH_PRODUCT", responce.data.data);
      commit("UPDATE_PRODUCT_ROW", state.productRows + 1);
      commit("SET_PRODUCTLOADING", false);
      return Product.create(responce.data.data);
    } catch (error) {
      commit("SET_PRODUCTLOADING", false);
      throw error.response.data.message;
    }
  },
  async edit_product({ commit }, payload) {
    commit("SET_PRODUCTLOADING", true);
    try {
      const responce = await axios.post(
        domain + `/produits/update/` + payload.get("id"),
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": " *",
          },
        }
      );
      commit("UPDATE_PRODUCT", responce.data.data);
      commit("SET_PRODUCTLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_PRODUCTLOADING", false);
      throw error.response.data.message;
    }
  },
  async delete_product({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_PRODUCTLOADING", true);
    try {
      await axios.delete(domain + `/produits/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_PRODUCT", payload);
      commit("UPDATE_PRODUCT_ROW", state.productRows - 1);
      commit("SET_PRODUCTLOADING", false);
      return true;
    } catch (error) {
      commit("SET_PRODUCTLOADING", false);
      throw error.response.data.message;
    }
  },
};
export default { state, mutations, getters, actions };
