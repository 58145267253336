import domain from "@/environment";
import SousTraitant from "../../models/sousTraitant";
import axios from "axios";

const state = {
  sousTraitant: null,
  sousTraitants: [],
  sousTraitantRows: 0,
  sousTraitantLoading: false,
};
const getters = {
  getsousTraitant: (state) => state.sousTraitant,
  getAllsousTraitant: (state) => state.sousTraitants,
  getTotalRowsousTraitant: (state) => state.sousTraitantRows,
  getLoadingsousTraitant: (state) => state.sousTraitantLoading,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGSOUSTRAITANT(state, payload) {
    if (payload) {
      state.sousTraitantLoading = payload;
    } else {
      state.sousTraitantLoading = false;
    }
  },
  SET_SOUSTRAITANT(state, payload) {
    if (payload) {
      state.sousTraitant = SousTraitant.create(payload);
    } else {
      state.sousTraitant = null;
    }
  },
  PUSH_SOUSTRAITANT(state, payload) {
    state.sousTraitants.push(SousTraitant.create(payload));
  },
  UPDATE_SOUSTRAITANT(state, payload) {
    for (let index = 0; index < state.sousTraitants.length; index++) {
      if (state.sousTraitants[index].id == payload.id) {
        state.sousTraitants[index].update(payload);
        break;
      }
    }
  },

  UPDATE_SOUSTRAITANT_ROW(state, payload) {
    if (payload) {
      state.sousTraitantRows = payload;
    } else {
      state.sousTraitantRows = 0;
    }
  },

  DELETE_SOUSTRAITANT(state, payload) {
    for (let index = 0; index < state.sousTraitants.length; index++) {
      if (state.sousTraitants[index].id == payload.id) {
        state.sousTraitants.splice(index, 1);
        break;
      }
    }
  },
  SET_SOUSTRAITANTS(state, payload) {
    if (payload) {
      state.sousTraitants = payload.map((item) => SousTraitant.create(item));
    } else {
      state.sousTraitants = null;
    }
  },
};

const actions = {
  async allsousTraitant({ commit }, payload) {
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      entreprise: payload.entreprise,
    };
    try {
      const response = await axios.get(domain + `/sous-traitants`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_SOUSTRAITANTS", response.data.data.data);
      commit("UPDATE_SOUSTRAITANT_ROW", response.data.data.total);
      return response.data.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async sousTraitant({ commit }, payload) {
    try {
      const response = await axios.get(domain + `/sous-traitants/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_SOUSTRAITANT", response.data.data);
      return response.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async store_sousTraitant({ commit }, payload) {
    commit("SET_LOADINGSOUSTRAITANT", true);
    try {
      console.log('payload', payload)
      const response = await axios.post(domain + `/sous-traitants`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
console.log('response1', response.data.data)
      commit("PUSH_SOUSTRAITANT", response.data.data);
      commit("SET_LOADINGSOUSTRAITANT", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADINGSOUSTRAITANT", false);
      return error.response.data.message;
    }
  },
  async edit_sousTraitant({ commit }, payload) {
    commit("SET_LOADINGSOUSTRAITANT", true);
    try {
      const response = await axios.put(
        domain + `/sous-traitants/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_SOUSTRAITANT", response.data.data);
      commit("SET_LOADINGSOUSTRAITANT", false);
      return response.data.data;
    } catch (error) {
      commit("SET_LOADINGSOUSTRAITANT", false);
      return error.response.data.message;
    }
  },
  async delete_sousTraitant({ commit }, payload) {
    commit("SET_LOADINGSOUSTRAITANT", true);
    try {
      await axios.delete(domain + `/sous-traitants/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_SOUSTRAITANT", payload);
      commit("SET_LOADINGSOUSTRAITANT", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGSOUSTRAITANT", false);
      return error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
