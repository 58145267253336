export default class Entreprise {
  constructor(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.email = data.email;
    this.email_activated_at = data.email_activated_at;
    this.rue = data.rue;
    this.cp = data.cp;
    this.ville = data.ville;
    this.tel = data.tel;
    this.phone = data.phone;
    this.logo = data.logo;
    this.SWIFT_BIC = data.SWIFT_BIC;
    this.IBAN = data.IBAN;
    this.siret = data.siret;
    this.num_tva = data.num_tva;
    this.pays = data.pays;
    this.titulaire_compte = data.titulaire_compte;
    this.created_at = data.created_at;
    this.payment_status = data.payment_status;
    this.count_users = data.count_users;
    this.count_payed_users = data.count_payed_users;
    this.register_done = data.register_done;
    this.local_trial = data.local_trial;
    this.rcs = data.rcs;
    this.logo_rge = data.logo_rge;
    this.nom_assurance = data.nom_assurance;
    this.num_assurance = data.num_assurance;
  }

  update(data) {
    this.lib = data.lib;
    this.email = data.email;
    this.rue = data.rue;
    this.cp = data.cp;
    this.ville = data.ville;
    this.tel = data.tel;
    this.phone = data.phone;
    this.logo = data.logo;
    this.SWIFT_BIC = data.SWIFT_BIC;
    this.IBAN = data.IBAN;
    this.siret = data.siret;
    this.num_tva = data.num_tva;
    this.pays = data.pays;
    this.titulaire_compte = data.titulaire_compte;
    this.created_at = data.created_at;
    this.payment_status = data.payment_status;
    this.count_users = data.count_users;
    this.count_payed_users = data.count_payed_users;
    this.register_done = data.register_done;
    this.local_trial = data.local_trial;
    this.rcs = data.rcs;
    this.logo_rge = data.logo_rge;
    this.nom_assurance = data.nom_assurance;
    this.num_assurance = data.num_assurance;
  }

  static create(data) {
    return new Entreprise(data);
  }
}
