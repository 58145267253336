import domain from "@/environment";
import categorieArticle from "../../models/categorieArticle";
import axios from "axios";

const state = {
  categorieArticle: null,
  categorieArticles: [],
  categorieArticleLoading: false,
  categorieArticleRows: 0,
};
const getters = {
  getcategorieArticle: (state) => state.categorieArticle,
  getAllcategorieArticle: (state) => state.categorieArticles,
  getLoadingcategorieArticle: (state) => state.categorieArticleLoading,
  getTotalRowcategorieArticle: (state) => state.categorieArticleRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADING_CATEGORIE_ARTICLE(state, payload) {
    if (payload) {
      state.categorieArticleLoading = payload;
    } else {
      state.categorieArticleLoading = false;
    }
  },
  SET_CATEGORIE_ARTICLE(state, payload) {
    if (payload) {
      state.categorieArticle = categorieArticle.create(payload);
    } else {
      state.categorieArticle = null;
    }
  },
  PUSH_CATEGORIE_ARTICLE(state, payload) {
    state.categorieArticles.push(categorieArticle.create(payload));
  },
  UPDATE_CATEGORIEARTICLE(state, payload) {
    for (let index = 0; index < state.categorieArticles.length; index++) {
      if (state.categorieArticles[index].id == payload.id) {
        state.categorieArticles[index].update(payload);
        break;
      }
    }
  },

  DELETE_CATEGORIE_ARTICLE(state, payload) {
    for (let index = 0; index < state.categorieArticles.length; index++) {
      if (state.categorieArticles[index].id == payload.id) {
        state.categorieArticles.splice(index, 1);
        break;
      }
    }
  },
  SET_CATEGORIE_ARTICLES(state, payload) {
    if (payload) {
      state.categorieArticles = payload.map((item) =>
        categorieArticle.create(item)
      );
    } else {
      state.categorieArticles = [];
    }
  },
  UPDATE_CATEGORIE_ARTICLE_ROW(state, payload) {
    if (payload) {
      state.categorieArticleRows = payload;
    } else {
      state.categorieArticleRows = 0;
    }
  },
};

const actions = {
  async allcategorieArticle({ commit }, payload) {
    commit("SET_LOADING_CATEGORIE_ARTICLE", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
    };
    try {
      const response = await axios.get(domain + "/categorie-article", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("UPDATE_CATEGORIE_ARTICLE_ROW", response.data.data.total);
      commit("SET_CATEGORIE_ARTICLES", response.data.data.data);
      commit("SET_LOADING_CATEGORIE_ARTICLE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_CATEGORIE_ARTICLE", false);
      throw false;
    }
  },
  async categorieArticle({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const response = await axios.get(
        domain + `/categorie-article/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_CATEGORIEARTICLE", response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async store_categorieArticle({ commit }, payload) {
    commit("SET_LOADING_CATEGORIE_ARTICLE", true);
    const params = {
      lib: payload.lib,
      categorie_metier_id: payload.categorie_metier_id,
    };
    try {
      const response = await axios.post(
        domain + `/categorie-article`,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
          params,
        }
      );

      commit("PUSH_CATEGORIEARTICLE", response.data.data);
      commit("UPDATE_CATEGORIE_ARTICLE_ROW", state.categorieArticleRows + 1);
      commit("SET_LOADING_CATEGORIE_ARTICLE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_CATEGORIE_ARTICLE", false);
      throw error.response.data.message;
    }
  },
  async edit_categorieArticle({ commit }, payload) {
    commit("SET_LOADING_CATEGORIE_ARTICLE", true);
    try {
      const response = await axios.put(
        domain + `/categorie-article/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_CATEGORIEARTICLE", response.data.data);
      commit("SET_LOADING_CATEGORIE_ARTICLE", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING_CATEGORIE_ARTICLE", false);
      throw error.response.data.message;
    }
  },
  async delete_categorieArticle({ commit }, payload) {
    commit("SET_LOADING_CATEGORIE_ARTICLE", true);
    try {
      await axios.delete(domain + `/categorie-article/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_CATEGORIEARTICLE", payload);
      commit("UPDATE_CATEGORIE_ARTICLE_ROW", state.categorieArticleRows - 1);
      commit("SET_LOADING_CATEGORIE_ARTICLE", false);
      return true;
    } catch (error) {
      commit("SET_LOADING_CATEGORIE_ARTICLE", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
