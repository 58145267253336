import domain from "@/environment";
import Entreprise from "../../models/entreprise";
import axios from "axios";

const state = {
  entreprise: [],
  entreprises: null,
  entrepriseRows: null,
  entrepriseloading: false,
  client_entreprise: [],
  entreprise_client: [],
  errorUpdate: null,
  statistique: {},
};
const getters = {
  getentreprise: (state) => state.entreprise,
  getstatistique: (state) => state.statistique,
  getAllentreprises: (state) => state.entreprises,
  getClientEntreprise: (state) => state.client_entreprise,
  getEntrepriseClient: (state) => state.entreprise_client,
  getTotalRowentreprise: (state) => state.entrepriseRows,
  getentrepriseLoading: (state) => state.entrepriseloading,
  getentrepriseErrorUpdate: (state) => state.errorUpdate,
};
const mutations = {
  DO_NOTHING() {},
  SET_ENTREPRISE_ERROR_UPDATE(state, payload) {
    state.errorUpdate = payload;
  },
  SET_LOADINGENTREPRISE(state, payload) {
    if (payload) {
      state.entrepriseloading = payload;
    } else {
      state.entrepriseloading = false;
    }
  },
  SET_ENTREPRISE(state, payload) {
    if (payload) {
      state.entreprise = Entreprise.create(payload);
    } else {
      state.entreprise = null;
    }
  },
  SET_STATISTIQUE(state, payload) {
    if (payload) {
      state.statistique = payload;
    } else {
      state.statistique = null;
    }
  },
  SET_CLIENT_ENTREPRISE(state, payload) {
    if (payload) {
      state.client_entreprise = payload[0].data;
      state.entreprise_client = payload[1].data;
    } else {
      state.client_entreprise = null;
    }
  },
  SET_ENTREPRISES(state, payload) {
    if (payload) {
      state.entreprises = payload.map((item) => Entreprise.create(item));
    } else {
      state.entreprises = [];
    }
  },
  PUSH_ENTREPRISE(state, payload) {
    state.entreprises.push(Entreprise.create(payload));
  },

  PUSH_ENTREPRISEUPDATE(state, payload) {
    state.entreprise.push(Entreprise.create(payload));
  },
  UPDATE_ENTREPRISE(state, payload) {
    for (let index = 0; index < state.entreprise.length; index++) {
      if (state.entreprise[index].id == payload.id) {
        state.entreprise[index].update(payload.response);
        break;
      }
    }
  },

  UPDATE_ENTREPRISES(state, payload) {
    for (let index = 0; index < state.entreprises.length; index++) {
      if (state.entreprises[index].id == payload.id) {
        state.entreprises[index].update(payload);
        break;
      }
    }
  },
  UPDATE_ENTREPRISE_ROW(state, payload) {
    if (payload) {
      state.entrepriseRows = payload;
    } else {
      state.entrepriseRows = 0;
    }
  },
  DELETE_ENTREPRISE(state, payload) {
    for (let index = 0; index < state.entreprises.length; index++) {
      if (state.entreprises[index].id == payload.id) {
        state.entreprises.splice(index, 1);
        break;
      }
    }
  },
};

const actions = {
  async all_entreprises({ commit }, payload) {
    commit("SET_LOADINGENTREPRISE", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      with_default: 1,
    };
    try {
      const responce = await axios.get(domain + "/entreprises", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_ENTREPRISES", responce.data.data.data);
      commit("UPDATE_ENTREPRISE_ROW", responce.data.data.total);
      commit("SET_LOADINGENTREPRISE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGENTREPRISE", false);
      throw error.response.data.message;
    }
  },
  async entreprise({ commit }, payload) {
    commit("SET_LOADINGENTREPRISE", true);
    try {
      const responce = await axios.get(domain + `/entreprises/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_ENTREPRISE", responce.data.data);
      commit("SET_LOADINGENTREPRISE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGENTREPRISE", false);
      throw error.response.data.message;
    }
  },
  async store_entreprise({ commit }, payload) {
    commit("SET_LOADINGENTREPRISE", true);
    try {
      const responce = await axios.post(domain + `/entreprises`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("PUSH_ENTREPRISE", responce.data.data);
      commit("PUSH_ENTREPRISEUPDATE", responce.data.data);
      commit("UPDATE_ENTREPRISE_ROW", state.entrepriseRows + 1);
      commit("SET_LOADINGENTREPRISE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGENTREPRISE", false);
      throw error.response.data.message;
    }
  },
  async edit_entreprise({ commit }, payload) {
    commit("SET_LOADINGENTREPRISE", true);
    commit("SET_ENTREPRISE_ERROR_UPDATE", null);
    try {
      const responce = await axios.post(
        domain + `/entreprises/update/` + payload.get("id"),
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_ENTREPRISE", {
        response: responce.data.data,
        id: payload.get("id"),
      });
      commit("SET_LOADINGENTREPRISE", false);
      return responce.data;
    } catch (error) {
      commit("SET_ENTREPRISE_ERROR_UPDATE", error);
      commit("SET_LOADINGENTREPRISE", false);
      throw error.response.data.message;
    }
  },
  async delete_entreprise({ commit }, payload) {
    commit("SET_LOADINGENTREPRISE", true);
    try {
      await axios.delete(domain + `/entreprises/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_ENTREPRISE", payload);
      commit("UPDATE_ENTREPRISE_ROW", state.entrepriseRows - 1);
      commit("SET_LOADINGENTREPRISE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGENTREPRISE", false);
      throw error.response.data.message;
    }
  },

  async active_email_entreprise({ commit }, payload) {
    commit("SET_LOADINGENTREPRISE", true);

    try {
      await axios.post(domain + `/email/activate`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_LOADINGENTREPRISE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGENTREPRISE", false);
      throw error.response.data.message;
    }
  },

  async resend_active_email({ commit }, payload) {
    commit("SET_LOADINGENTREPRISE", true);
    try {
      await axios.post(domain + `/email/resend`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_LOADINGENTREPRISE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGENTREPRISE", false);
      throw error.response.data.message;
    }
  },

  async search_client_entreprise({ commit }, payload) {
    commit("SET_LOADINGENTREPRISE", true);
    const params = {
      search: payload.search,
    };
    try {
      const responce = await axios.get(domain + "/search-client-entreprise", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_CLIENT_ENTREPRISE", responce.data.data);
      commit("SET_LOADINGENTREPRISE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGENTREPRISE", false);
      throw error.response.data.message;
    }
  },

  async statistique_entreprise({ commit }) {
    commit("SET_LOADINGENTREPRISE", true);
    try {
      const response = await axios.get(domain + "/statistiques", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_STATISTIQUE", response.data);
      commit("SET_LOADINGENTREPRISE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGENTREPRISE", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
