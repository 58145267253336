import domain from "@/environment";
import Travaux from "../../models/travaux";
import axios from "axios";

const state = {
  travail: null,
  travaux: null,
  travauxLoading: false,
  travauxRows: null,
};
const getters = {
  gettravaux: (state) => state.travail,
  getAlltravaux: (state) => state.travaux,
  getLoadingtravaux: (state) => state.travauxLoading,
  getTotalRowTravaux: (state) => state.travauxRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGtravaux(state, payload) {
    if (payload) {
      state.travauxLoading = payload;
    } else {
      state.travauxLoading = false;
    }
  },
  SET_TRAVAIL(state, payload) {
    if (payload) {
      state.travail = Travaux.create(payload);
    } else {
      state.travail = null;
    }
  },
  PUSH_TRAVAUX(state, payload) {
    state.travaux.push(Travaux.create(payload));
  },
  UPDATE_TRAVAUX(state, payload) {
    for (let index = 0; index < state.travaux.length; index++) {
      if (state.travaux[index].id == payload.id) {
        state.travaux[index].update(payload);
        break;
      }
    }
  },

  DELETE_TRAVAUX(state, payload) {
    for (let index = 0; index < state.travaux.length; index++) {
      if (state.travaux[index].id == payload.id) {
        state.travaux.splice(index, 1);
        break;
      }
    }
  },
  SET_TRAVAUX(state, payload) {
    if (payload) {
      state.travaux = payload.map((item) => Travaux.create(item));
    } else {
      state.travaux = null;
    }
  },

  UPDATE_TRAVAUX_ROW(state, payload) {
    if (payload) {
      state.travauxRows = payload;
    } else {
      state.travauxRows = 0;
    }
  },
};

const actions = {
  async alltravaux({ commit }, payload) {
    commit("SET_TRAVAUX");
    commit("SET_LOADINGtravaux", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      favoris: payload.favoris,
      entreprise: payload.entreprise,
      default: payload.default,
      with_default: payload.with_default,
    };

    try {
      const responce = await axios.get(domain + "/TypeTravaux", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_TRAVAUX", responce.data.data.data);
      commit("UPDATE_TRAVAUX_ROW", responce.data.data.total);
      commit("SET_LOADINGtravaux", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGtravaux", false);
      throw error.response.data.message;
    }
  },
  async travaux({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/TypeTravaux/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_TRAVAIL", responce.data.data);
      return responce.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async store_travaux({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGtravaux", true);
    try {
      const responce = await axios.post(domain + `/TypeTravaux`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("PUSH_TRAVAUX", responce.data.data);
      commit("UPDATE_TRAVAUX_ROW", state.travauxRows + 1);
      commit("SET_LOADINGtravaux", false);
      return Travaux.create(responce.data.data);
    } catch (error) {
      commit("SET_LOADINGtravaux", false);
      throw error.response.data.message;
    }
  },
  async edit_travaux({ commit }, payload) {
    commit("SET_LOADINGtravaux", true);
    try {
      const responce = await axios.put(
        domain + `/TypeTravaux/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_TRAVAUX", responce.data.data);
      commit("SET_LOADINGtravaux", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGtravaux", false);
      throw error.response.data.message;
    }
  },
  async delete_travaux({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGtravaux", true);
    try {
      await axios.delete(domain + `/TypeTravaux/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_TRAVAUX", payload);
      commit("UPDATE_TRAVAUX_ROW", state.travauxRows - 1);
      commit("SET_LOADINGtravaux", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGtravaux", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
