import domain from "@/environment";
import Gallery from "../../models/gallery";
import axios from "axios";

const state = {
  gallery: null,
  galleries: null,
  galleryLoading: false,
  galleryRows: null,
};
const getters = {
  getgallery: (state) => state.gallery,
  getAllgallery: (state) => state.galleries,
  getLoadinggallery: (state) => state.galleryLoading,
  getTotalRowgallery: (state) => state.galleryRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGgallery(state, payload) {
    if (payload) {
      state.galleryLoading = payload;
    } else {
      state.galleryLoading = false;
    }
  },
  SET_GALLERY(state, payload) {
    if (payload) {
      state.gallery = Gallery.create(payload);
    } else {
      state.gallery = null;
    }
  },
  PUSH_GALLERY(state, payload) {
    state.galleries.push(Gallery.create(payload));
  },
  UPDATE_GALLERY(state, payload) {
    for (let index = 0; index < state.galleries.length; index++) {
      if (state.galleries[index].id == payload.id) {
        state.galleries[index].update(payload);
        break;
      }
    }
  },

  DELETE_GALLERY(state, payload) {
    for (let index = 0; index < state.galleries.length; index++) {
      if (state.galleries[index].id == payload.id) {
        state.galleries.splice(index, 1);
        break;
      }
    }
  },
  SET_GALLERIES(state, payload) {
    if (payload) {
      state.galleries = payload.map((item) => Gallery.create(item));
    } else {
      state.galleries = null;
    }
  },

  UPDATE_GALLERY_ROW(state, payload) {
    if (payload) {
      state.galleryRows = payload;
    } else {
      state.galleryRows = null;
    }
  },
};

const actions = {
  async allgallery({ commit }, payload) {
    commit("SET_LOADINGgallery", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
    };

    try {
      const responce = await axios.get(domain + "/gallery", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_GALLERIES", responce.data.data.data);
      commit("UPDATE_GALLERY_ROW", responce.data.data.total);
      commit("SET_LOADINGgallery", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGgallery", false);
      throw error.response.data.message;
    }
  },
  async store_gallery({ commit }, payload) {
    commit("SET_LOADINGgallery", true);
    try {
      const responce = await axios.post(domain + `/gallery`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
          "Content-Type": "multipart/form-data",
        },
      });

      commit("PUSH_GALLERY", responce.data.data);
      commit("UPDATE_GALLERY_ROW", state.galleryRows + 1);
      commit("SET_LOADINGgallery", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGgallery", false);
      throw error.response.data.message;
    }
  },
  async edit_gallery({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGgallery", true);
    try {
      const responce = await axios.post(
        domain + `/gallery/update/` + payload.get("id"),
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": " *",
          },
        }
      );

      commit("UPDATE_GALLERY", responce.data.data);
      commit("SET_LOADINGgallery", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGgallery", false);
      throw error.response.data.message;
    }
  },
  async delete_gallery({ commit }, payload) {
    commit("SET_LOADINGgallery", true);
    try {
      await axios.delete(domain + `/gallery/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_GALLERY", payload);
      commit("UPDATE_GALLERY_ROW", state.galleryRows - 1);
      commit("SET_LOADINGgallery", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGgallery", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
