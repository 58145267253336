export default class File {
  constructor(data) {
    this.id = data.id;
    this.ocr_dossier_id = data.ocr_dossier_id;
    this.ocr_file_type_id = data.ocr_file_type_id;
    this.status = data.status;
  }
  update(data) {
    this.id = data.id;
    this.ocr_dossier_id = data.ocr_dossier_id;
    this.ocr_file_type_id = data.ocr_file_type_id;
    this.status = data.status;
  }

  static create(data) {
    return new File(data);
  }
}
