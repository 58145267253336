import domain from "@/environment";
import Modele from "../../models/modele";
import axios from "axios";
import index from '../index'
const state = {
  modele: null,
  modeles: [],
  modeleLoading: false,
};
const getters = {
  getmodele: (state) => state.modele,
  getAllmodele: (state) => state.modeles,
  getLoadingmodele: (state) => state.modeleLoading,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGMODELE(state, payload) {
    if (payload) {
      state.modeleLoading = payload;
    } else {
      state.modeleLoading = false;
    }
  },
  SET_MODELE(state, payload) {
    if (payload) {  
      state.modele = Modele.create(payload);
    } else {
      state.modele = null;
    }
  },
  PUSH_MODELE(state, payload) {
    state.modeles.push(Modele.create(payload));
  },
  UPDATE_MODELE(state, payload) {
    for (let index = 0; index < state.modeles.length; index++) {
      if (state.modeles[index].id == payload.id) {
        state.modeles[index].update(payload);
        break;
      }
    }
  },

  DELETE_MODELE(state, payload) {
    for (let index = 0; index < state.modeles.length; index++) {
      if (state.modeles[index].id == payload.id) {
        state.modeles.splice(index, 1);
        break;
      }
    }
  },
  SET_MODELES(state, payload) {
    if (payload) {
     state.modeles = payload.map((item) => Modele.create(item));
    } else {
      state.modeles = null;
    }
  },
};

const actions = {
  async allmodele({ commit }) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/modeles`+"?lang="+index.state.selectedLang.iso_code, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_MODELES", responce.data.data);    
      return responce.data.data;  
    } catch (error) {
      return error.response.data.message;
    }
  },
  async modele({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/modeles/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_MODELE", responce.data.data);
      return responce.data;
    } catch (error) {
      return error.response.data.message;
    }
  }, 
  async store_modele({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGMODELE", true);
    try {
      const responce = await axios.post(domain + `/modeles`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("PUSH_MODELE", responce.data.data);
      commit("SET_LOADINGMODELE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGMODELE", false);
      return error.response.data.message;
    }
  },
  async edit_modele({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGMODELE", true);
    try {
      const responce = await axios.put(
        domain + `/modeles/` + payload.id,
        { lib: payload.lib, lang: payload.lang, cgv: payload.cgv },
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_MODELE", responce.data.data);
      commit("SET_LOADINGMODELE", false);
      return responce.data.data;
    } catch (error) {
      commit("SET_LOADINGMODELE", false);
      return error.response.data.message;
    }
  },
  async delete_modele({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_LOADINGMODELE", true);
    try {
      await axios.delete(domain + `/modeles/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_MODELE", payload);
      commit("SET_LOADINGMODELE", false);
      return true;
    } catch (error) {
      return error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
