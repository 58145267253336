import domain from "@/environment";
import axios from "axios";
const state = {
  datafile: [],
  loadingFiche: null,
};
const getters = {
  getdatafile: (state) => state.datafile,
  getLoadingFiche: (state) => state.loadingFiche,
  getfile: (state) => state.file,
};
const mutations = {
  GET_DATA_FILE(state, payload) {
    if (payload) {
      state.datafile = payload;
    } else {
      state.datafile = [];
    }
  },
  UPDATE_DATAFILE(state, payload) {
    for (let index = 0; index < state.datafile.length; index++) {
      if (state.datafile[index].id == payload.id) {
        state.datafile[index] = payload;
        break;
      }
    }
  },

  DELETE_FROM_DATAFILE(state, payload) {
    for (let index = 0; index < state.datafile.length; index++) {
      if (state.datafile[index].id == payload.id) {
        state.datafile.splice(index, 1);
        break;
      }
    }
  },
  SET_DATE_FILE(state, payload) {
    if (payload) state.datafile = payload;
    else state.datafile = [];
  },

  ADD_TO_DATAFILE(state, payload) {
    state.datafile.push(payload);
  },
};
const actions = {
  async delete_from_datafile({ commit }, payload) {
    try {
      await axios.delete(domain + `/ficheClient/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_FROM_DATAFILE", payload);
      return true;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async update_datafile({ commit }, payload) {
    var fileupdate = new FormData();
    fileupdate.append("lib", payload.lib);
    fileupdate.append("category_file_id", payload.id);
    fileupdate.append("description", payload.description);
    fileupdate.append("client_id", payload.id);
    fileupdate.append("file", payload.file);

    try {
      await axios.put(domain + `/ficheClient/` + payload.id, fileupdate, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("UPDATE_DATAFILE", payload);
      return true;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async get_data_file({ commit }, payload) {
    const params = {
      client_id: payload.id,
    };
    try {
      const responce = await axios.get(domain + "/ficheClient", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_DATE_FILE", responce.data.data.data);
      return responce.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },

  async add_to_datafile({ commit }, payload) {
    try {
      const responce = await axios.post(domain + `/ficheClient`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("ADD_TO_DATAFILE", responce.data.data.data);
      return responce.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
