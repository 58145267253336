export default class Agenda {
    constructor(data) {
        this.id = data.id;
        this.name = data.titre;
        this.start = data.date_debut.slice(0, 10) + "T" + data.date_debut.slice(11)
        this.end = data.date_fin.slice(0, 10) + "T" + data.date_fin.slice(11)
        this.type = data.type;
        this.description = data.description
        this.user = data.user
        this.client = data.client
        this.done = data.done
        this.entreprise = data.entreprise
        this.cp = data.cp
        this.ville = data.ville
        this.adresse = data.adresse
        this.statut=data.statut
        if (!data.done) {
            if (data.type == 'activity.appel')
                this.color = "#52A500"
            else if (data.type == 'activity.rendez-vous')
                this.color = "#E9B525"
            else if (data.type == 'activity.tache')
                this.color = "#28367A"
        }
        else
            this.color = "#a9b5bf"
    }
    update(data) {
        this.id = data.id;
        this.name = data.titre;
        this.statut=data.statut
        this.start = data.date_debut.slice(0, 10) + "T" + data.date_debut.slice(11)
        this.end = data.date_fin.slice(0, 10) + "T" + data.date_fin.slice(11)
        this.type = data.type;
        this.description = data.description
        this.user = data.user
        this.client = data.client
        this.entreprise = data.entreprise
        this.done = data.done
        this.cp = data.cp
        this.ville = data.ville
        this.adresse = data.adresse
        
        if (!data.done) {
            if (data.type == 'activity.appel')
                this.color = "#52A500"
            else if (data.type == 'activity.rendez-vous')
                this.color = "#E9B525"
            else if (data.type == 'activity.tache')
                this.color = "#28367A"
        }
        else
            this.color = "#a9b5bf"
    }

    static create(data) {
        return new Agenda(data);
    }
}
