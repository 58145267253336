export default class Notification {
    constructor(data) {
        this.id = data.id;
        this.seen = data.seen == 1 ? true : false;
        this.id_ref = data.id_ref
        this.date = data.date
        this.created_at = data.created_at
        this.type = data.type
        if(data.message == 'activity.appel')
            this.message = "Appel"
        else if(data.message == 'activity.tache')
            this.message = "Tache"
        else if(data.message == 'activity.rendez-vous')
            this.message = "Rendez vous"
        else
            this.message = data.message
    }
    update(data) {
        this.id = data.id;
        this.seen = data.seen == 1 ? true : false;
        this.id_ref = data.id_ref
        this.date = data.date
        this.created_at = data.created_at
        this.type = data.type
        if(data.message == 'activity.appel')
            this.message = "Appel"
        else if(data.message == 'activity.tache')
            this.message = "Tache"
        else if(data.message == 'activity.rendez-vous')
            this.message = "Rendez vous"
        else
            this.message = data.message
    }

    static create(data) {
        return new Notification(data);
    }
}
