// import User from "./user";
import Entreprise from "./entreprise";
import store from "../store/index";

export default class Product {
  constructor(data) {
    this.id = data.id;
    if (data.entreprise) this.entreprise = Entreprise.create(data.entreprise);
    else this.entreprise = { lib: "Toutes les entreprises" };
    this.is_default = data.is_default;
    if (!data.version || store.state.user.user.role == "user.super-admin") {
      this.lib = data.lib;
      this.ref = data.ref;
      this.desc = data.desc;
      this.unit_price = data.unit_price;
      this.type_unite = data.type_unite;
      this.tva = data.tva;
      this.favoris = data.favoris == 1 ? true : false;
      this.fabricant = data.fabricant;
      this.prix_achat = data.prix_achat;
      this.img = data.img;
      this.prestation = data.prestation;
      this.categorie_prestations = data.categorie_prestations;
      this.categorie_article = data.categorie_article;
    } else {
      this.lib = data.version.lib ? data.version.lib : data.lib;
      this.ref = data.version.ref ? data.version.ref : data.ref;
      this.desc = data.version.desc ? data.version.desc : data.desc;
      this.unit_price = data.version.unit_price
        ? data.version.unit_price
        : data.unit_price;
      this.type_unite = data.version.type_unite
        ? data.version.type_unite
        : data.type_unite;
      this.tva = data.version.tva ? data.version.tva : data.tva;
      this.favoris = data.version.favoris ? data.version.favoris : data.favoris;
      this.fabricant = data.version.fabricant
        ? data.version.fabricant
        : data.fabricant;
      this.prix_achat = data.version.prix_achat
        ? data.version.prix_achat
        : data.prix_achat;
      this.img = data.version.img ? data.version.img : data.img;
      this.categorie_prestations = data.version.categorie_prestations
        ? data.version.categorie_prestations
        : data.categorie_prestations;
      this.categorie_article = data.version.categorie_article
        ? data.version.categorie_article
        : data.categorie_article;
    }
  }
  update(data) {
    this.id = data.id;
    if (data.entreprise) this.entreprise = Entreprise.create(data.entreprise);
    else this.entreprise = { lib: "Toutes les entreprises" };
    this.is_default = data.is_default;
    if (!data.version || store.state.user.user.role == "user.super-admin") {
      this.lib = data.lib;
      this.ref = data.ref;
      this.desc = data.desc;
      this.unit_price = data.unit_price;
      this.type_unite = data.type_unite;
      this.tva = data.tva;
      this.favoris = data.favoris == 1 ? true : false;
      this.fabricant = data.fabricant;
      this.prix_achat = data.prix_achat;
      this.img = data.img;
      this.prestation = data.prestation;
      this.categorie_prestations = data.categorie_prestations;
      this.categorie_article = data.categorie_article;
    } else {
      this.lib = data.version.lib ? data.version.lib : data.lib;
      this.ref = data.version.ref ? data.version.ref : data.ref;
      this.desc = data.version.desc ? data.version.desc : data.desc;
      this.unit_price = data.version.unit_price
        ? data.version.unit_price
        : data.unit_price;
      this.type_unite = data.version.type_unite
        ? data.version.type_unite
        : data.type_unite;
      this.tva = data.version.tva ? data.version.tva : data.tva;
      this.favoris = data.version.favoris ? data.version.favoris : data.favoris;
      this.fabricant = data.version.fabricant
        ? data.version.fabricant
        : data.fabricant;
      this.prix_achat = data.version.prix_achat
        ? data.version.prix_achat
        : data.prix_achat;
      this.img = data.version.img ? data.version.img : data.img;
      this.categorie_prestations = data.version.categorie_prestations
        ? data.version.categorie_prestations
        : data.categorie_prestations;
      this.categorie_article = data.version.categorie_article
        ? data.version.categorie_article
        : data.categorie_article;
    }
  }
  static create(data) {
    return new Product(data);
  }
}
