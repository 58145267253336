import domain from "@/environment";
import Dictionnaire from "../../models/dictionnaire";
import axios from "axios";

const state = {
  multiLangues: [],
  multiLangueLoading: false,
  multiLangueRows: 0,
};
const getters = {
  getmultiLangues: (state) => state.multiLangues,
  getLoadingMultiLangue: (state) => state.multiLangueLoading,
  getTotalMultiLangue: (state) => state.multiLangueRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_LOADINGMULTILANGUE(state, payload) {
    if (payload) {
      state.multiLangueLoading = payload;
    } else {
      state.multiLangueLoading = false;
    }
  },
  PUSH_MULTILANGUE(state, payload) {
    state.multiLangues.push(Dictionnaire.create(payload));
  },
  UPDATE_MULTILANGUE(state, payload) {
    for (let index = 0; index < state.multiLangues.length; index++) {
      if (state.multiLangues[index].id == payload.id) {
        state.multiLangues[index].update(payload);
        break;
      }
    }
  },

  DELETE_MULTILANGUE(state, payload) {
    for (let index = 0; index < state.multiLangues.length; index++) {
      if (state.multiLangues[index].id == payload.id) {
        state.multiLangues.splice(index, 1);
        break;
      }
    }
  },
  SET_MULTILANGUE(state, payload) {
    if (payload) {
      state.multiLangues = payload.map((item) => Dictionnaire.create(item));
    } else {
      state.multiLangues = null;
    }
  },
  UPDATE_MULTILANGUE_ROW(state, payload) {
    if (payload) {
      state.multiLangueRows = payload;
    } else {
      state.multiLangueRows = 0;
    }
  },
};

const actions = {
  async allMultiLangue({ commit }, payload) {
    commit("SET_LOADINGMULTILANGUE", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      lang: payload.lang,
      search: payload.search,
    };
    try {
      const responce = await axios.get(domain + `/multi-langues`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_MULTILANGUE", responce.data.data.data);
      commit("UPDATE_MULTILANGUE_ROW", responce.data.data.total);
      commit("SET_LOADINGMULTILANGUE", false);

      return responce.data.data;
    } catch (error) {
      commit("SET_LOADINGMULTILANGUE", false);
      return error.response.data.message;
    }
  },
  async store_MultiLangue({ commit }, payload) {
    commit("SET_LOADINGMULTILANGUE", true);
    const params = {
      lib: payload.lib,
      key: payload.key,
      lang: payload.lang.iso_code,
    };
    try {
      const responce = await axios.post(domain + `/multi-langues`, params, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("PUSH_MULTILANGUE", responce.data.data);
      commit("SET_LOADINGMULTILANGUE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGMULTILANGUE", false);
      return error.response.data.message;
    }
  },
  async edit_MultiLangue({ commit }, payload) {
    commit("SET_LOADINGMULTILANGUE", true);
    try {
      const responce = await axios.put(
        domain + `/multi-langues/${payload.id}`,
        { lib: payload.lib, lang: payload.lang },
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_MULTILANGUE", responce.data.data);
      commit("SET_LOADINGMULTILANGUE", false);
      return responce.data.data;
    } catch (error) {
      commit("SET_LOADINGMULTILANGUE", false);
      return error.response.data.message;
    }
  },
  async delete_MultiLangue({ commit }, payload) {
    commit("SET_LOADINGMULTILANGUE", true);
    try {
      await axios.delete(domain + `/multi-langues/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_MULTILANGUE", payload);
      commit("SET_LOADINGMULTILANGUE", false);
      return true;
    } catch (error) {
      commit("SET_LOADINGMULTILANGUE", false);
      return error.response.data.message;
    }
  },
  async import_MultiLangue({ commit }, payload) {
    commit("SET_LOADINGMULTILANGUE", true);
    try {
      const responce = await axios.post(
        domain + `/multi-langues/import?lang=` + payload.lang,
        payload.file,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      commit("SET_LOADINGMULTILANGUE", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGMULTILANGUE", false);
      return error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
