export default class Langue {
  constructor(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.iso_code = data.iso_code;
    this.img = data.img;
    this.is_default = data.is_default;
    this.active = data.active == "1" ? true : false;
  }
  update(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.iso_code = data.iso_code;
    this.img = data.img;
    this.is_default = data.is_default;
    this.active = data.active == "1" ? true : false;
  }

  static create(data) {
    return new Langue(data);
  }
}
