import domain from "@/environment";
import Country from "../../models/country";
import axios from "axios";

const state = {
  country: null,
  countries: [],
  countryLoading: false,
  countryRows: null,
};
const getters = {
  getcountry: (state) => state.country,
  getAllcountries: (state) => state.countries,
  getcontryLoading: (state) => state.countryLoading,
  getTotalRowcountry: (state) => state.countryRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_country(state, payload) {
    if (payload) {
      state.country = Country.create(payload);
    } else {
      state.country = null;
    }
  },

  SET_COUNTRIES(state, payload) {
    if (payload) {
      state.countries = payload.map((item) => Country.create(item));
    } else {
      state.countries = null;
    }
  },
  SET_COUNTRYLOADING(state, payload) {
    if (payload) {
      state.countryLoading = payload;
    } else {
      state.countryLoading = false;
    }
  },
  PUSH_COUNTRY(state, payload) {
    state.countries.push(Country.create(payload));
  },
  UPDATE_COUNTRY(state, payload) {
    payload.refObject.update(payload.response);
  },
  DELETE_country(state, payload) {
    for (let index = 0; index < state.countries.length; index++) {
      if (state.countries[index].id == payload.id) {
        state.countries.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_COUNTRY_ROW(state, payload) {
    if (payload) {
      state.countryRows = payload;
    } else {
      state.countryRows = 0;
    }
  },
};

const actions = {
  async all_countries({ commit }, payload) {
    commit("SET_COUNTRYLOADING", true);
    const params = {
      page: payload.page,
      per_page: payload.perPage,
    };
    try {
      const responce = await axios.get(domain + `/country`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_COUNTRIES", responce.data.data.data);
      commit("UPDATE_COUNTRY_ROW", responce.data.data.total);
      commit("SET_COUNTRYLOADING", false);
      return true;
    } catch (error) {
      commit("SET_COUNTRYLOADING", false);
      return error;
    }
  },
  async country_id({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/country`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("SET_COUNTRIES", responce.data.data);
      return true;
    } catch (error) {
      return error.response.data.message;
    }
  },

  async store_country({ commit }, payload) {
    commit("SET_COUNTRYLOADING", true);
    try {
      const responce = await axios.post(domain + `/country`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("PUSH_COUNTRY", responce.data.data);
      commit("UPDATE_COUNTRY_ROW", state.countryRows + 1);
      commit("SET_COUNTRYLOADING", false);
      return true;
    } catch (error) {
      commit("SET_COUNTRYLOADING", false);
      throw error.response.data.message;
    }
  },
  async edit_country({ commit }, payload) {
    commit("SET_COUNTRYLOADING", true);
    try {
      const responce = await axios.put(
        domain + `/country/` + payload.data.id,
        null,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
          params: payload.data,
        }
      );
      commit("UPDATE_COUNTRY", {
        refObject: payload.refObject,
        response: responce.data.data,
      });
      commit("SET_COUNTRYLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_COUNTRYLOADING", false);
      throw error.response.data.message;
    }
  },
  async delete_country({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_COUNTRYLOADING", true);
    try {
      const response = await axios.delete(domain + `/country/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_country", payload);
      commit("UPDATE_COUNTRY_ROW", state.countryRows - 1);
      commit("SET_COUNTRYLOADING", false);

      return response;
    } catch (error) {
      commit("SET_COUNTRYLOADING", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
