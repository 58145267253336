export default class categorieMetier {
  constructor(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.categorie_article = data.categorie_article;
  }
  update(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.categorie_article = data.categorie_article;
  }

  static create(data) {
    return new categorieMetier(data);
  }
}
