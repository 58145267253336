export default class Modele {
  constructor(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.cgv = data.cgv;
  }
  update(data) {
    this.id = data.id;
    this.lib = data.lib;
    this.cgv = data.cgv;
  }

  static create(data) {
    return new Modele(data);
  }
}
