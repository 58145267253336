import domain from "@/environment";
import Client from "../../models/client";
import axios from "axios";

const state = {
  client: null,
  clients: [],
  clientLoading: false,
  clientRows: null,
};
const getters = {
  getclient: (state) => state.client,
  getAllclients: (state) => state.clients,
  getclientLoading: (state) => state.clientLoading,
  getTotalRowclient: (state) => state.clientRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_client(state, payload) {
    if (payload) {
      state.client = Client.create(payload);
    } else {
      state.client = null;
    }
  },

  SET_CLIENTS(state, payload) {
    if (payload) {
      state.clients = payload.map((item) => Client.create(item));
    } else {
      state.clients = null;
    }
  },
  SET_CLIENTLOADING(state, payload) {
    if (payload) {
      state.clientLoading = payload;
    } else {
      state.clientLoading = false;
    }
  },
  PUSH_CLIENT(state, payload) {
    state.clients.push(Client.create(payload));
  },
  UPDATE_CLIENT(state, payload) {
    for (let index = 0; index < state.clients.length; index++) {
      if (state.clients[index].id == payload.id) {
        state.clients[index].update(payload);
        break;
      }
    }
  },
  DELETE_client(state, payload) {
    for (let index = 0; index < state.clients.length; index++) {
      if (state.clients[index].id == payload.id) {
        state.clients.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_CLIENT_ROW(state, payload) {
    if (payload) {
      state.clientRows = payload;
    } else {
      state.clientRows = 0;
    }
  },
};

const actions = {
  async all_clients({ commit }, payload) {
    commit("SET_CLIENTLOADING", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      entreprise_id: payload.entreprise_id,
      type: payload.type,
    };
    try {
      const responce = await axios.get(domain + `/clients`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_CLIENTS", responce.data.data.data);
      commit("UPDATE_CLIENT_ROW", responce.data.data.total);
      commit("SET_CLIENTLOADING", false);
      return true;
    } catch (error) {
      commit("SET_CLIENTLOADING", false);
      return error.response.data.message;
    }
  },
  async client({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const responce = await axios.get(domain + `/clients/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_client", responce.data.data);
      return responce.data;
    } catch (error) {
      return error.response.data.message;
    }
  },
  async store_client({ commit }, payload) {
    commit("SET_CLIENTLOADING", true);
    try {
      const responce = await axios.post(domain + `/clients`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("PUSH_CLIENT", responce.data.data);
      commit("UPDATE_CLIENT_ROW", state.clientRows + 1);
      commit("SET_CLIENTLOADING", false);

      return Client.create(responce.data.data);
    } catch (error) {
      commit("SET_CLIENTLOADING", false);
      throw error.response.data.message;
    }
  },
  async edit_client({ commit }, payload) {
    commit("SET_CLIENTLOADING", true);
    try {
      const responce = await axios.put(
        domain + `/clients/` + payload.data.id,
        null,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
          params: payload.data,
        }
      );
      commit("UPDATE_CLIENT", responce.data.data);
      commit("SET_CLIENTLOADING", false);
      return true;
    } catch (error) {
      commit("SET_CLIENTLOADING", false);
      throw error.response.data.message;
    }
  },
  async delete_client({ commit }, payload) {
    commit("DO_NOTHING");
    commit("SET_CLIENTLOADING", true);
    try {
      const response = await axios.delete(domain + `/clients/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_client", payload);
      commit("UPDATE_CLIENT_ROW", state.clientRows - 1);
      commit("SET_CLIENTLOADING", false);

      return response;
    } catch (error) {
      commit("SET_CLIENTLOADING", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
