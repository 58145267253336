import domain from "@/environment";
import axios from "axios";

const state = {
  searches: null,
  searchRows: 0,
  searchLoading: false,
};
const getters = {
  getSearches: (state) => state.searches,
  getSearchRows: (state) => state.searchRows,
  getLoadingsearch: (state) => state.searchLoading,
};
const mutations = {
  DO_NOTHING() {},

  SET_LOADINGSEARCHES(state, payload) {
    if (payload) {
      state.searchLoading = payload;
    } else {
      state.searchLoading = false;
    }
  },
  SET_SEARCHES(state, payload) {
    if (payload) {
      state.searches = payload;
    } else {
      state.searches = [];
    }
  },
  SET_SEARCHROWS(state, payload) {
    if (payload) {
      state.searchRows = payload;
    } else {
      state.searchRows = 0;
    }
  },
};

const actions = {
  async getSearches({ commit }) {
    commit("SET_LOADINGSEARCHES", true);
    try {
      const responce = await axios.get(domain + "/searches", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_SEARCHES", responce.data.data.data);
      commit("SET_LOADINGSEARCHES", false);
      return responce.data;
    } catch (error) {
      commit("SET_LOADINGSEARCHES", false);
      return error.response.data.message;
    }
  },

  async searches({ commit }, payload) {
    commit("SET_LOADINGSEARCHES", true);
    const params = {
      search: payload.search,
      per_page: payload.per_page,
      page: payload.page,
    };

    try {
      const responce = await axios.get(domain + "/searchByClientIndex", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });

      commit("SET_SEARCHES", responce.data.data);
      commit("SET_SEARCHROWS", responce.data.data.length);
      commit("SET_LOADINGSEARCHES", false);
      return responce.data;
    } catch (error) {
      commit("SET_SEARCHROWS");
      commit("SET_LOADINGSEARCHES", false);
      return error.response.data.message;
    }
  },

  clearSearches({ commit }) {
    commit("SET_SEARCHES");
    commit("SET_SEARCHROWS");
  },
};

export default { state, mutations, getters, actions };
